<template>
  <div class="news-container" id="news">
    <div class="section">
      <div class="news-out">
        <div class="image">
          <NewsSvg />
        </div>
        <h2 class="header">
          اخبار و مقالات
          <span class="bold">اِکس پلیمر</span>
        </h2>
        <div class="details">
          برای مشاهده محتوای محتوای مطالب آن را انتخاب کنید و برای دریافت آخرین
          اخبار و مقالات ایمیل خود را ثبت کنید.
        </div>
        <div class="input">
          <input placeholder="آدرس ایمیل " />
          <button>ثبت</button>
        </div>
      </div>
      <div
        class="spinner-border text-primary"
        role="status"
        v-if="loading"
      ></div>
      <div class="news" v-else>
        <div class="new" v-for="(post, index) in posts" v-bind:key="index">
          <img :src="post.img" />
          <div class="text">{{ post.title }}</div>
          <a class="link" :href="post.link">مطالعه کنید </a>
        </div>
        <!-- <div class="new">
          <img />
          <div class="text"></div>
          <div class="link"></div>
        </div>
        <div class="new">
          <img />
          <div class="text"></div>
          <div class="link"></div>
        </div> -->
      </div>

      <a href="https://expolymer.ir/blog" class="buttons">
        <button>مشاهده همه</button>
      </a>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import NewsSvg from "./svgs/NewsSvg .vue";
import axios from "axios";
import { onBeforeMount } from "vue";
import * as cheerio from "cheerio";
const posts = ref([]);
const loading = ref(false);

const getPosts = async () => {
  try {
    loading.value = true;
    const { data } = await axios.get(
      `${process.env.VUE_APP_BASE_WORDPRESS}/blog/wp-json/wp/v2/posts`
    );
    let temp = [];
    for (let index = 0; index < data.length; index++) {
      const element = data[index];
      const $ = cheerio.load(element.content.rendered);
      let photoSrc = $("img").first().attr("src");
      if (index < 4) {
        temp.push({
          title: element?.title.rendered,
          img: photoSrc,
          link: element.link,
        });
      }
    }
    posts.value = temp;
    loading.value = false;
  } catch (err) {
    loading.value = false;
    console.log(err);
  }
};
onBeforeMount(() => {
  getPosts();
});
</script>
<style lang="scss" scoped>
.news-container {
  position: relative;
  width: 1240px;
  display: flex;
  padding: 100px 0;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  z-index: 10;
  /* overflow: hidden; */
  .news-out {
    position: absolute;
    color: white;
    top: 70px;
    right: -10px;
    width: 340px;
    background-color: #2068e1;
    height: 390px;
    border-radius: 20px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-box-shadow: -50px 0px 15px -34px rgba(0, 0, 0, 0.48);
    -moz-box-shadow: -50px 0px 15px -34px rgba(0, 0, 0, 0.48);
    box-shadow: -50px 0px 15px -34px rgba(0, 0, 0, 0.48);
    background-image: url("../assets/hex.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    .image {
      animation: 2s infinite alternate ease-in-out upAndDown;
      svg {
        fill: white;
        width: 100px;
        height: 100px;
      }
    }
    .header {
      font-size: 28px;
      font-weight: bold;
      .bold {
        color: #0f2851;
      }
    }
    .details {
      font-size: 14px;
    }
    .input {
      height: 51px;
      padding: 6px;
      background-color: white;
      border-radius: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        height: 100%;
        border: none;
        outline: none;
        padding-right: 10px;
      }
      button {
        width: 60px;
        height: 100%;
        border-radius: 10px;
        border: none;
        background-color: #0f2851;
        color: white;
        &:hover {
          transform: scale(0.9);
          background-color: #2068e1;
          transition: 0.3s;
        }
      }
    }
  }
  .section {
    width: 100%;
    height: 350px;
    display: flex;
    background-color: #0f2851;
    color: white;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px 0 0 20px;
    padding: 20px;
    padding-right: 60px;
    .news {
      display: flex;
      align-self: flex-end;
      /* margin-right: auto; */
      overflow: hidden;
      /* padding-right: 140px; */
      gap: 15px;
      width: 75%;
      /* flex: 3; */

      .new {
        width: 260px;
        display: flex;
        flex-direction: column;
        .text {
          font-size: 14px;
        }
        a {
          margin-top: 5px;
          text-decoration: none;
          font-size: 14px;
          cursor: pointer;
        }
        img {
          border-radius: 8px;
          height: 130px;
          margin-bottom: 10px;
        }
      }
    }
    .buttons {
      border-top: 1px solid white;
      direction: ltr;
      button {
        width: 150px;
        height: 36px;
        margin-top: 20px;
        border-radius: 10px;
        border: none;
        background-color: #2068e1;
        color: white;
        &:hover {
          transition: 0.2s;
          background-color: white;
          color: #0f2851;
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .news-container {
    width: 90%;
    .news-out {
      width: 300px;
      height: 390px;
      .image {
        svg {
          width: 90px;
          height: 90px;
        }
      }
      .header {
        font-size: 26px;
      }
      .details {
        font-size: 13px;
      }
      .input {
        height: 40px;
        padding: 5px;
        input {
          font-size: 14px;
        }
        button {
          width: 55px;
          font-size: 14px;
        }
      }
    }
    .section {
      width: 100%;
      height: 350px;
      display: flex;
      background-color: #0f2851;
      color: white;
      flex-direction: column;
      border-radius: 20px 0 0 20px;
      padding: 20px;
      padding-right: 60px;
      .news {
        display: flex;
        width: 780px;
        flex: 3;
        overflow: hidden;
        .new {
          width: 260px;
          display: flex;
          flex-direction: column;
        }
      }
      .buttons {
        border-top: 1px solid white;
        direction: ltr;
        button {
          width: 140px;
          height: 30px;
          font-size: 14px;
        }
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .news-container {
    width: 95%;
    .news-out {
      width: 250px;
      height: 390px;
      .image {
        svg {
          width: 80px;
          height: 80px;
        }
      }
      .header {
        font-size: 23px;
      }
      .details {
        font-size: 11px;
      }
      .input {
        height: 35px;
        padding: 4px;
        input {
          font-size: 12px;
        }
        button {
          width: 45px;
          font-size: 12px;
        }
      }
    }
    .section {
      width: 100%;
      height: 350px;
      display: flex;
      background-color: #0f2851;
      color: white;
      flex-direction: column;
      border-radius: 20px 0 0 20px;
      padding: 20px;
      padding-right: 60px;
      .news {
        display: flex;
        width: 780px;
        flex: 3;
        .new {
          width: 260px;
          display: flex;
          flex-direction: column;
        }
      }
      .buttons {
        border-top: 1px solid white;
        direction: ltr;
        button {
          width: 130px;
          height: 30px;
          font-size: 13px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .news-container {
    padding-bottom: 0;
    margin-bottom: 6rem;
  }
}
</style>
