<template>
  <div class="admin-container">
    <div v-if="admin === 2" class="add-polymer">
      <h4>تعریف پلیمر</h4>
      <div>
        <Modal ref="UploadPhotoRef" size="md">
          <div>
            <div>آپلود لوگو</div>
            <div>
              <input @change="uploadLogo" type="file" />
            </div>
          </div>
        </Modal>
        <Modal ref="removeMaterialRef" size="sm">
          <div>
            <div class="mb-4">آیا از حذف پلیمر مطمئن هستید؟</div>
            <button class="btn btn-danger btn-sm me-2" @click="deleteItem">
              حذف
            </button>
            <button
              class="btn btn-secondary btn-sm"
              @click="removeMaterialRef.closeModal()"
            >
              انصراف
            </button>
          </div>
        </Modal>
        <Modal ref="updateMaterialRef" size="md">
          <div class="row">
            <div class="col-3">
              <label class="form-label">نام</label>
              <input
                class="form-control"
                v-model="nameEdit"
                autocomplete="off"
              />
            </div>
            <div class="col-3">
              <label class="form-label">پتروشیمی</label>
              <input
                class="form-control"
                v-model="petroEdit"
                autocomplete="off"
              />
            </div>
            <div class="col-3">
              <label class="form-label">نماد 1</label>
              <input
                class="form-control"
                v-model="namadEdit"
                autocomplete="off"
              />
            </div>
            <div class="col-3">
              <label class="form-label">نماد 2</label>
              <input
                class="form-control"
                v-model="namad2Edit"
                autocomplete="off"
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-3">
              <label class="form-label">گروه بندی</label>
              <input
                class="form-control"
                v-model="namad3Edit"
                autocomplete="off"
              />
            </div>
            <div class="col-3">
              <label class="form-label">نماد 3</label>
              <input
                class="form-control"
                v-model="namad4Edit"
                autocomplete="off"
              />
            </div>
            <div class="col-3">
              <label class="form-label">نماد 4</label>
              <input
                class="form-control"
                v-model="categoryEdit"
                autocomplete="off"
              />
            </div>
            <div class="col-2 mt-auto mb-1">
              <button class="btn btn-sm btn-primary" @click="updateMaterial">
                بروزرسانی
              </button>
            </div>
          </div>
        </Modal>
        <div class="row">
          <div class="col-3">
            <label class="form-label">نام</label>
            <input class="form-control" v-model="name" autocomplete="off" />
          </div>
          <div class="col-3">
            <label class="form-label">پتروشیمی</label>
            <input class="form-control" v-model="petro" autocomplete="off" />
          </div>
          <div class="col-3">
            <label class="form-label">نماد 1</label>
            <input class="form-control" v-model="namad" autocomplete="off" />
          </div>
          <div class="col-3">
            <label class="form-label">نماد 2</label>
            <input class="form-control" v-model="namad2" autocomplete="off" />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-3">
            <label class="form-label">گروه بندی</label>
            <input class="form-control" v-model="namad3" autocomplete="off" />
          </div>
          <div class="col-3">
            <label class="form-label">نماد 3</label>
            <input class="form-control" v-model="namad4" autocomplete="off" />
          </div>
          <div class="col-3">
            <label class="form-label">نماد 4</label>
            <input class="form-control" v-model="category" autocomplete="off" />
          </div>
          <div class="col-3">
            <label class="form-label">نوع</label>
            <select class="form-select">
              <option selected value="1">اصلی</option>
              <option value="2">off 1</option>
              <option value="3">off 2</option>
              <option value="4">off 3</option>
              <option value="5">off 4</option>
              <option value="6">body</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-2 mt-3 mb-1">
            <button class="btn btn-sm btn-primary" @click="addMaterial">
              اضافه کردن
            </button>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="addPositionRef" size="sm">
      <div>
        <div class="mb-3">اولویت پلیمر</div>
        <input
          class="form-control form-control-sm mb-4"
          v-model="positionNum"
        />
        <button class="btn btn-primary btn-sm me-2" @click="addPosition()">
          ثبت
        </button>
        <button
          class="btn btn-secondary btn-sm"
          @click="addPositionRef.closeModal()"
        >
          انصراف
        </button>
      </div>
    </Modal>
    <Modal ref="updatePriceRef" size="lg">
      <div class="updateprice-modal">
        <div class="mb-3">بروزرسانی قیمت</div>
        <div class="row">
          <div class="col-3">
            <DatePicker
              class="adminDate"
              auto-submit
              v-model="fromDate"
            ></DatePicker>
          </div>
          <div class="col-3">
            <DatePicker
              class="adminDate"
              auto-submit
              v-model="toDate"
            ></DatePicker>
          </div>
          <div class="col-3">
            <button class="btn btn-primary btn-sm" @click="getPrices">
              جست و جو
            </button>
          </div>
        </div>

        <div>
          <table class="table">
            <thead>
              <tr>
                <th>قیمت</th>
                <th>قیمت بورس</th>
                <th>تمام قیمت</th>
                <th>حمل و نقل</th>
                <th>تاریخ</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in prices" v-bind:key="index">
                <td>
                  <input-facade
                    :formatter="evenMoney"
                    mask="#############"
                    class="form-control form-control-sm"
                    required=""
                    v-model="item.price"
                    autocomplete="off"
                  />
                </td>
                <td>
                  <input-facade
                    :formatter="evenMoney"
                    mask="#############"
                    class="form-control form-control-sm"
                    required=""
                    v-model="item.boorsPrice"
                    autocomplete="off"
                  />
                </td>
                <td>
                  <input-facade
                    :formatter="evenMoney"
                    mask="#############"
                    v-model="item.allPrice"
                    class="form-control form-control-sm"
                    required=""
                    autocomplete="off"
                  />
                </td>
                <td>
                  <input-facade
                    :formatter="evenMoney"
                    mask="#############"
                    v-model="item.transportPrice"
                    class="form-control form-control-sm"
                    required=""
                    autocomplete="off"
                  />
                </td>
                <td>{{ converDate(item.createdOn) }}</td>
                <td>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="editPriceDate(item)"
                  >
                    بروزرسانی
                  </button>
                  <button
                    class="btn btn-danger btn-sm ms-2"
                    @click="deletePrice(item)"
                  >
                    حذف
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
    <Modal ref="deletePriceRef" size="sm">
      <div>
        <div class="mb-4">آیا از حذف قیمت مطمئن هستید؟</div>
        <button class="btn btn-danger btn-sm me-2" @click="deletePriceFunc">
          حذف
        </button>
        <button
          class="btn btn-secondary btn-sm"
          @click="deletePriceRef.closeModal()"
        >
          انصراف
        </button>
      </div>
    </Modal>
    <div class="add-price mt-4">
      <h4>ثبت قیمت</h4>
      <div>
        <div class="row mb-2">
          <div class="col-2">
            <input
              class="form-control"
              placeholder="نام پلیمر"
              autocomplete="off"
              v-model="searchQuery"
            />
          </div>
          <div class="col-2">
            <button class="btn btn-primary btn-sm mt-1" @click="search">
              جست جو
            </button>
          </div>
        </div>
        <div>
          <ul class="nav nav-underline">
            <li class="nav-item" @click="changeTab(1)">
              <a
                :class="
                  tabActive === 1
                    ? 'nav-link active'
                    : 'nav-link cursor-pointer'
                "
                >اصلی</a
              >
            </li>
            <li class="nav-item" @click="changeTab(2)">
              <a
                :class="
                  tabActive === 2
                    ? 'nav-link active'
                    : 'nav-link cursor-pointer'
                "
                >off 1</a
              >
            </li>
            <li class="nav-item" @click="changeTab(3)">
              <a
                :class="
                  tabActive === 3
                    ? 'nav-link active'
                    : 'nav-link cursor-pointer'
                "
                >off 2</a
              >
            </li>
            <li class="nav-item" @click="changeTab(4)">
              <a
                :class="
                  tabActive === 4
                    ? 'nav-link active'
                    : 'nav-link cursor-pointer'
                "
                >off 3</a
              >
            </li>
            <li class="nav-item" @click="changeTab(5)">
              <a
                :class="
                  tabActive === 5
                    ? 'nav-link active'
                    : 'nav-link cursor-pointer'
                "
                >off 4</a
              >
            </li>
            <li class="nav-item" @click="changeTab(6)">
              <a
                :class="
                  tabActive === 6
                    ? 'nav-link active'
                    : 'nav-link cursor-pointer'
                "
                >body</a
              >
            </li>
          </ul>
        </div>
        <div
          v-if="loading"
          class="spinner-border text-primary"
          role="status"
        ></div>
        <div class="table-container">
          <table v-if="!loading" class="table" ref="content">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">نام</th>
                <th scope="col">پتروشیمی</th>
                <th scope="col">نماد 1</th>
                <th scope="col">2نماد</th>
                <th scope="col">گروه بندی</th>
                <th scope="col">نماد3</th>
                <th scope="col">نماد4</th>
                <th scope="col">قیمت</th>
                <th scope="col">قیمت بورس</th>
                <th scope="col">تمام قیمت</th>
                <th scope="col">حمل و نقل</th>
                <th scope="col">تاریخ</th>
                <th scope="col">نمایش</th>
                <th scope="col">
                  <button
                    class="btn btn-primary btn-sm"
                    @click="saveAllItem(materials)"
                  >
                    ثبت همه
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in materials" v-bind:key="index">
                <td class="position" @click="openPosition(item.id)">
                  {{ item.position }}
                </td>
                <td class="position" @click="openModalIcon(item.id)">
                  {{ item.name }}
                </td>
                <td class="position" @click="editMaterialModal(item)">
                  {{ item.petro }}
                </td>
                <td>{{ item.namad }}</td>
                <td>{{ item.namad2 }}</td>
                <td>{{ item.namad3 }}</td>
                <td>{{ item?.namad4 }}</td>
                <td>{{ item?.category }}</td>
                <td>
                  <input-facade
                    :formatter="evenMoney"
                    mask="#############"
                    class="form-control form-control-sm"
                    required=""
                    v-model="item.price"
                    autocomplete="off"
                  />
                </td>
                <td>
                  <input-facade
                    :formatter="evenMoney"
                    mask="#############"
                    class="form-control form-control-sm"
                    required=""
                    v-model="item.boorsPrice"
                    autocomplete="off"
                  />
                </td>
                <td>
                  <input-facade
                    :formatter="evenMoney"
                    mask="#############"
                    v-model="item.allPrice"
                    class="form-control form-control-sm"
                    required=""
                    autocomplete="off"
                  />
                </td>
                <td>
                  <input-facade
                    :formatter="evenMoney"
                    mask="#############"
                    v-model="item.transportPrice"
                    class="form-control form-control-sm"
                    required=""
                    autocomplete="off"
                  />
                </td>
                <td>
                  <DatePicker
                    class="adminDate"
                    auto-submit
                    v-model="item.createdAt"
                  ></DatePicker>
                </td>
                <td class="buttons">
                  <button
                    @click="hideMaterial(0, item.id)"
                    v-if="item.hide === 1"
                    class="btn btn-primary btn-sm"
                  >
                    نمایش
                  </button>
                  <button
                    @click="hideMaterial(1, item.id)"
                    v-if="item.hide === 0"
                    class="btn btn-danger btn-sm"
                  >
                    عدم نمایش
                  </button>
                  <button
                    @click="hidePrice(0, item.id)"
                    v-if="item.hidePrice === 1"
                    class="btn btn-primary btn-sm ms-1"
                  >
                    نمایش قیمت
                  </button>
                  <button
                    @click="hidePrice(1, item.id)"
                    v-if="item.hidePrice === 0"
                    class="btn btn-danger btn-sm ms-1"
                  >
                    عدم نمایش قیمت
                  </button>
                </td>
                <td class="buttons">
                  <button
                    class="btn btn-primary btn-sm me-1"
                    @click="saveItem(item)"
                  >
                    ثبت
                  </button>
                  <button
                    class="btn btn-secondary btn-sm me-1"
                    @click="openEditModal(item.id)"
                  >
                    ویرایش
                  </button>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="openDeleteModal(item)"
                    v-if="admin === 2"
                  >
                    حذف
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import Modal from "../components/Modal.vue";
import Mark from "mark.js";
import axios from "axios";
import { ref, onMounted } from "vue";
import { useToast } from "vue-toastification";
import { onBeforeMount } from "vue";
import router from "@/router";
import moment from "jalali-moment";
import { watch } from "vue";

const tabActive = ref(1);
const changeTab = (num) => {
  tabActive.value = num;
};
const UploadPhotoRef = ref(null);
const updatePriceRef = ref(null);
const searchQuery = ref("");
const content = ref(null);
const toast = useToast();
let marker = null;
const name = ref("");
const petro = ref("");
const namad = ref("");
const namad2 = ref("");
const namad3 = ref("");
const namad4 = ref("");
const category = ref("");
const idEdit = ref(null);
const nameEdit = ref("");
const petroEdit = ref("");
const namadEdit = ref("");
const namad2Edit = ref("");
const namad3Edit = ref("");
const namad4Edit = ref("");
const categoryEdit = ref("");
const loading = ref(false);
const iconId = ref(null);
const removeMaterialRef = ref(null);
const updateMaterialRef = ref(null);
const deletePriceRef = ref(null);
const openModalIcon = async (e) => {
  iconId.value = e;
  UploadPhotoRef.value.openModal();
};
const editMaterialModal = async (e) => {
  console.log(e);
  idEdit.value = e.id;
  nameEdit.value = e.name;
  petroEdit.value = e.petro;
  namadEdit.value = e.namad;
  namad2Edit.value = e.namad2;
  namad3Edit.value = e.namad3;
  namad4Edit.value = e.namad4;
  categoryEdit.value = e.category;
  updateMaterialRef.value.openModal();
};
const removePriceId = ref(0);
const deletePrice = (e) => {
  removePriceId.value = e.id;
  deletePriceRef.value.openModal();
};
const deletePriceFunc = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/price/delete`,
      {
        id: Number(removePriceId.value),
      },
      config
    );
    toast.success("قیمت  با موفقیت حذف شد.", {
      position: "top-right",
      timeout: 2000,
    });
    getPrices();
    loading.value = false;
    deletePriceRef.value.closeModal();
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const materialEdit = ref("");
const openEditModal = (e) => {
  materialEdit.value = e;
  getPrices();
  updatePriceRef.value.openModal();
};
const prices = ref();
const toDate = ref(null);
const fromDate = ref(null);
const getPrices = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const { data } = await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/material/prices`,
      {
        materialId: materialEdit.value,
        fromDate: fromDate.value,
        toDate: toDate.value,
      },
      config
    );
    prices.value = data;

    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const uploadLogo = async (e) => {
  UploadPhotoRef.value.closeModal();
  loading.value = true;
  const token = localStorage.getItem("token");
  const formData = new FormData();
  formData.append("FileDetails", e.target.files[0]);
  formData.append("FileType", 1);
  formData.append("materialId", Number(iconId.value));
  const config = {
    headers: {
      Authorization: "Bearer " + token,
      accept: "*/*",
      "Content-Type": "multipart/form-data",
    },
  };
  try {
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/material/icon`,
      formData,
      config
    );
    // console.log(data);
    toast.success("لوگو با موفقیت اضافه شد . ", {
      position: "top-right",
      timeout: 2000,
    });
    loading.value = false;
  } catch (err) {
    console.log(err);
    loading.value = false;
  }
};
const evenMoney = (value) => {
  if (value.unmasked.length > 1) {
    const formatted = value.unmasked
      .split("")
      .reverse()
      .join("")
      .match(/\d{1,3}/g)
      .join(",")
      .split("")
      .reverse()
      .join("");

    value.masked = formatted;
  }
};
const deleteMaterial = ref("");
const openDeleteModal = (e) => {
  deleteMaterial.value = e;
  removeMaterialRef.value.openModal();
};
const deleteItem = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/material/delete`,
      {
        id: Number(deleteMaterial.value.id),
      },
      config
    );
    toast.success("قیمت پلیمر با موفقیت حذف شد.", {
      position: "top-right",
      timeout: 2000,
    });
    getMaterial();
    loading.value = false;
    removeMaterialRef.value.closeModal();
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const admin = ref(1);
const getAccsess = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const id = localStorage.getItem("id");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const { data } = await axios.get(
      `${process.env.VUE_APP_BASE_API}/api/admin/${id}`,
      config
    );
    admin.value = data;
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};

const editPriceDate = async (e) => {
  console.log(e);
  if (!e.price) {
    e.price = 0;
  }
  if (!e.allPrice) {
    e.allPrice = 0;
  }
  if (!e.boorsPrice) {
    e.boorsPrice = 0;
  }
  if (!e.transportPrice) {
    e.transportPrice = 0;
  }
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/price/edit`,
      {
        item: e,
      },
      config
    );
    toast.success("قیمت پلیمر با موفقیت ویرایش شد.", {
      position: "top-right",
      timeout: 2000,
    });
    updatePriceRef.value.closeModal();
    getMaterial();
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const addPositionRef = ref(null);
const positionNum = ref(0);
const positionMaterial = ref("");
const openPosition = (e) => {
  positionMaterial.value = e;
  getPosition();
  addPositionRef.value.openModal();
};
const getPosition = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const { data } = await axios.get(
      `${process.env.VUE_APP_BASE_API}/api/material/position/${positionMaterial.value}`,
      config
    );
    positionNum.value = data;

    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const addPosition = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/material/position`,
      { id: positionMaterial.value, position: positionNum.value },
      config
    );
    getMaterial();
    loading.value = false;
    addPositionRef.value.closeModal();
    toast.success("پلیمر با موفقست بروز رسانی شد . ", {
      position: "top-right",
      timeout: 2000,
    });
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};

const saveItem = async (e) => {
  if (!e.price) {
    e.price = 0;
  }
  if (!e.allPrice) {
    e.allPrice = 0;
  }
  if (!e.boorsPrice) {
    e.boorsPrice = 0;
  }
  if (!e.transportPrice) {
    e.transportPrice = 0;
  }

  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/price`,
      {
        item: e,
      },
      config
    );
    toast.success("قیمت پلیمر با موفقیت ثبت شد.", {
      position: "top-right",
      timeout: 2000,
    });
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const converDate = (e) => {
  const toDay = moment(e).format("jYYYY/jM/jD");
  return toDay;
};
const saveAllItem = async (e) => {
  for (let index = 0; index < e.length; index++) {
    const element = e[index];
    if (!element.price) {
      element.price = 0;
    }
    if (!element.allPrice) {
      element.allPrice = 0;
    }
    if (!element.boorsPrice) {
      element.boorsPrice = 0;
    }
    if (!element.transportPrice) {
      element.transportPrice = 0;
    }
    const toDay = moment().format("jYYYY/jM/jD");
    if (!element.createdAt || element.createdAt.length < 3) {
      element.createdAt = toDay;
    }
  }
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/priceAll`,
      {
        items: e,
      },
      config
    );
    toast.success("قیمت پلیمر ها با موفقیت ثبت شد.", {
      position: "top-right",
      timeout: 2000,
    });
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const hidePrice = async (e, id) => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/material/hidePrice`,
      {
        hidePrice: e,
        id: id,
      },
      config
    );
    toast.success("پلیمر با موفقیت بروز  شد.", {
      position: "top-right",
      timeout: 2000,
    });
    getMaterial();
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const hideMaterial = async (e, id) => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/material/hide`,
      {
        hide: e,
        id: id,
      },
      config
    );
    toast.success("پلیمر با موفقیت بروز  شد.", {
      position: "top-right",
      timeout: 2000,
    });
    getMaterial();
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const addMaterial = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/material`,
      {
        name: name.value,
        petro: petro.value,
        namad: namad.value,
        namad2: namad2.value,
        namad3: namad3.value,
        namad4: namad4.value,
        category: category.value,
      },
      config
    );
    toast.success("پلیمر با موفقیت اضافه شد.", {
      position: "top-right",
      timeout: 2000,
    });
    getMaterial();
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const updateMaterial = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/update/material`,
      {
        id: idEdit.value,
        name: nameEdit.value,
        petro: petroEdit.value,
        namad: namadEdit.value,
        namad2: namad2Edit.value,
        namad3: namad3Edit.value,
        namad4: namad4Edit.value,
        category: categoryEdit.value,
      },
      config
    );
    toast.success("پلیمر با موفقیت بروز شد.", {
      position: "top-right",
      timeout: 2000,
    });
    updateMaterialRef.value.closeModal();
    getMaterial();
    loading.value = false;
  } catch (err) {
    toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
const materials = ref([]);
const getMaterial = async () => {
  try {
    loading.value = true;
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
      },
    };
    const { data } = await axios.get(
      `${process.env.VUE_APP_BASE_API}/api/material`,
      config
    );

    materials.value = data;
    loading.value = false;
  } catch (err) {
    if (err.response.status === 401) {
      router.push("/admin/login");
      toast.error("دوباره وارد شوید", {
        position: "top-right",
        timeout: 2000,
      });
    } else {
      toast.error("مشکلی پیش آمده دوباره تلاش کنید.", {
        position: "top-right",
        timeout: 2000,
      });
    }
    loading.value = false;
    console.log(err);
  }
};
onBeforeMount(() => {
  getAccsess();
  getMaterial();
});
watch(content, () => {
  marker = new Mark(content.value);
});
onMounted(() => {
  marker = new Mark(content.value);
});
const scrollToFirstMatch = () => {
  const highlightedElements = content.value.querySelectorAll("mark");
  console.log(highlightedElements);

  if (highlightedElements.length > 0) {
    highlightedElements[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }
};
const search = () => {
  marker.unmark({
    done: () => {
      if (searchQuery.value) {
        marker.mark(searchQuery.value, {
          separateWordSearch: false,
          done: () => {
            scrollToFirstMatch();
          },
        });
      }
    },
  });
};
</script>
<style lang="scss" scoped>
.position {
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
a {
  text-decoration: none !important;
  color: inherit !important;
}
table {
  thead {
    font-size: 13px;

    tr {
      position: sticky;
      top: 0;
    }
  }

  tbody {
    font-size: 13px;

    input {
      max-width: 100px;
    }
  }
}

.buttons {
  button {
    font-size: 11px;
  }
}

.admin-container {
  padding: 2rem;
  direction: rtl;
}
</style>
