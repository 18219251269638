<template>
  <div class="page-container">
    <NavbarComponent />
    <div class="main-container">
      <div class="container">
        <div class="topbanner">
          <div class="back-img"><img src="../assets/PolymerPage.webp" /></div>
          <div class="address">صفحه اصلی/محصولات/مواد پلیمری/پلی پروپیان</div>
          <div class="title">پلی پروپیان</div>
          <div class="buttons">
            <button>دانلود دیتاشیت</button>
            <button>آرشیو قیمت</button>
          </div>
          <div class="tools">
            <div>افزودن به علاقه مندی</div>
            <div>اشتراک گذاری</div>
            <div>نمودار قیمت</div>
          </div>
        </div>
        <div class="nav">
          <div class="links">
            <div class="link active">مشخصات عمومی</div>
            <div class="link">خواص فیزیکی</div>
            <div class="link">خواص حرارتی</div>
            <div class="link">خواص نوری</div>
            <div class="link">مواد کاربردی</div>
            <div class="link">توضیحات تکمیلی</div>
          </div>
        </div>
        <div class="part">
          <div class="header">مشخصات عمومی</div>
          <div class="body">
            <div class="row">
              <div class="title">نام گرید</div>
              <div class="text">SE-3000</div>
            </div>
            <div class="row">
              <div class="title">تولید کننده</div>
              <div class="text">بانیار پلیمر</div>
            </div>
            <div class="row">
              <div class="title">دسته ها</div>
              <div class="text">
                <span class="tag">#پلیمر</span>
                <span class="tag">#ترمو</span>
                <span class="tag">#پلی</span>
              </div>
            </div>
            <div class="row">
              <div class="title">کاربرد ها</div>
              <div class="text">
                <span class="tag">#پلیمر</span>
                <span class="tag">#ترمو</span>
                <span class="tag">#پلی</span>
              </div>
            </div>
            <div class="row">
              <div class="title">افزوده شده با</div>
              <div class="text">تاخیر دهنده شعله</div>
            </div>
            <div class="row">
              <div class="title">ویژگی ها</div>
              <div class="text">خود خاموش شونده</div>
            </div>
          </div>
        </div>
        <div class="part">
          <div class="header">مشخصات عمومی</div>
          <div class="body">
            <table>
              <thead>
                <tr>
                  <td></td>
                  <td>مقدار</td>
                  <td>واحد</td>
                  <td>شرایط آزمون</td>
                  <td>روش آزمون</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Bead Size</td>
                  <td>1-07</td>
                  <td>mm</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>BlowingAgent</td>
                  <td>7-4</td>
                  <td>%</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Density</td>
                  <td>0.013</td>
                  <td>g/cm³</td>
                  <td>first Expansion</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Density</td>
                  <td>0.009</td>
                  <td>g/cm³</td>
                  <td>second Expansion</td>
                  <td></td>
                </tr>
                <tr>
                  <td>K-Value</td>
                  <td>57-52</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Pentane Content</td>
                  <td>5.2</td>
                  <td>%</td>
                  <td>{{ "5.2<" }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Specific Gravity</td>
                  <td>1.03</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Styrene Residual Monomer</td>
                  <td>2000</td>
                  <td>ppm</td>
                  <td>2000></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <FooterComponent />
    </div>
  </div>
</template>
<script setup>
import FooterComponent from "@/components/FooterComponent.vue";
import NavbarComponent from "@/components/NavbarComponent.vue";
import { useRoute } from "vue-router";
const route = useRoute();
console.log(route.params);
</script>
<style lang="scss" scoped>
.main-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: transparent;
  /* overflow: hidden; */
  width: 100%;
  .container {
    min-height: 100vh;

    .topbanner {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 30px;
      .back-img {
        position: absolute;
        z-index: -1;
        top: -50px;
        img {
          height: 350px;
          width: 900px;
        }
      }
      .address {
        color: gray;
      }
      .title {
        font-size: 50px;
        color: #2068e1;
        margin-bottom: 20px;
        font-weight: bold;
      }
      .buttons {
        display: flex;
        gap: 20px;
        :first-child {
          background-color: #0f2851;
          color: white;
          border: none;
          padding: 5px 0;
          border-radius: 6px;
          width: 140px;
        }
        :last-child {
          background-color: #2068e1;
          border: 1px solid white;
          color: white;

          padding: 5px 0px;
          border-radius: 6px;
          width: 140px;
        }
      }
      .tools {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid gray;
        border-radius: 8px;
        padding: 7px;
        color: gray;
        font-size: 12px;
        margin-top: 20px;
        margin-bottom: 60px;
        div {
          border-left: 2px solid gray;
          padding: 0 10px;
          &:last-child {
            border: none;
          }
        }
      }
    }
    .nav {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      .links {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 45px;
        background-color: white;
        padding: 10px 40px;
        border-radius: 8px;
        -webkit-box-shadow: 0px 0px 36px -9px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 36px -9px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 36px -9px rgba(0, 0, 0, 0.75);
        .link {
          color: gray;
          &.active {
            color: #2068e1;
          }
        }
      }
    }
  }
}
</style>
