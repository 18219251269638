<template>
  <svg id="Layer_4" data-name="Layer 4" viewBox="0 0 593.15 497.6">
    <defs></defs>
    <path
      class="cls-1"
      d="M580.38,72.02c-12.09-6.87-27.48-2.63-34.35,9.48-3.93,6.93-4.22,14.93-1.48,21.79,0,0,0,.01.01.01l-36.19,20.84c-4.2-6.94-9.26-13.36-15.07-19.08-5.82-5.74-12.39-10.78-19.62-14.95L345.41,16.05c-3.19-1.84-6.5-3.51-9.84-4.96-.28-.12-.56-.24-.85-.35-.33-2.17-1.82-4.01-3.93-4.77-27.62-9.96-58.72-7.46-84.61,7.48L117.91,87.51c-31.06,17.94-50.36,51.36-50.36,87.23v68.06c0,1.65.68,3.15,1.76,4.24.36.37.78.68,1.23.95v74.87c0,16.21,4.07,32.29,11.78,46.51.01.03.03.05.04.08l-37.16,21.4c-4.85-6.35-12.33-9.86-20.01-9.86-4.16,0-8.39,1.03-12.28,3.21-12.15,6.8-16.5,22.15-9.7,34.31,6.79,12.14,22.14,16.48,34.29,9.68,11.27-6.3,15.82-19.95,11.01-31.59l37.22-21.42s.05.07.07.11c8.4,13.22,20.02,24.36,33.6,32.2l128.27,74.06c3.19,1.84,6.5,3.51,9.84,4.96.28.12.56.24.85.36h.01c.33,2.17,1.83,4.01,3.93,4.77,11.05,3.97,22.65,5.96,34.25,5.96,17.41,0,34.82-4.48,50.36-13.45l128.27-74.06c31.06-17.94,50.36-51.36,50.36-87.23v-68.05c0-2.22-1.21-4.15-3-5.19v-74.88c0-15.82-3.87-31.16-10.92-44.81l36.17-20.84c2.02,2.69,4.6,5.02,7.71,6.78,12.1,6.87,27.49,2.63,34.36-9.47h0s-.01-.01-.01-.01c6.88-12.1,2.65-27.49-9.47-34.37ZM516.54,174.74v74.88c-1.79,1.04-3,2.97-3,5.19v68.06c0,31.6-17,61.04-44.36,76.84l-128.27,74.06c-22.79,13.17-50.2,15.36-74.53,6.59-.69-.26-1.39-.37-2.07-.37-1.38,0-2.68.49-3.72,1.31t-.02-.01c-.22-.09-.44-.18-.66-.28-3.13-1.36-6.24-2.92-9.23-4.65l-128.27-74.06c-12.75-7.36-23.65-17.81-31.54-30.23-1.16-1.81-2.25-3.68-3.26-5.55-7.23-13.35-11.06-28.45-11.06-43.66v-74.87c1.79-1.04,3-2.97,3-5.19v-68.06c0-31.6,17-61.04,44.36-76.84L252.18,23.84c13.68-7.9,29.02-11.85,44.36-11.85,10.24,0,20.48,1.76,30.23,5.28,2.07.75,4.2.28,5.74-.94h.01c.22.08.43.18.65.27,3.14,1.36,6.24,2.92,9.23,4.65l128.28,74.05c28.29,16.34,45.86,46.78,45.86,79.44Z"
    ></path>
    <path
      class="cls-1"
      d="M274.29,156.83h44.51c5.07,0,9.18-4.11,9.18-9.18s-4.11-9.18-9.18-9.18h-44.51c-5.07,0-9.18,4.11-9.18,9.18s4.11,9.18,9.18,9.18Z"
    ></path>
    <path
      class="cls-1"
      d="M296.58,338.93c-5.07,0-9.18,4.11-9.18,9.18s4.11,9.18,9.18,9.18,9.17-4.11,9.17-9.18-4.11-9.18-9.17-9.18Z"
    ></path>
    <path
      class="cls-1"
      d="M465.32,112.4l-135.04-77.97c-20.87-12.05-46.59-12.05-67.46,0L127.76,112.4c-20.88,12.06-33.74,34.32-33.74,58.42v155.95c0,24.1,12.86,46.37,33.74,58.42l135.05,77.97c20.87,12.05,46.59,12.05,67.46,0l135.05-77.97c20.88-12.06,33.74-34.32,33.74-58.42v-155.95c0-24.1-12.86-46.37-33.74-58.42ZM121.25,321.32l-1.91-140.13c-.13-9.06,13.94-9.24,14.07-.19l1.91,140.13c.12,9.05-13.94,9.23-14.07.19ZM247.62,371.19c-20.28,0-36.72-16.44-36.72-36.72v-171.34c0-20.28,16.44-36.72,36.72-36.72h97.91c20.28,0,36.72,16.44,36.72,36.72v171.34c0,20.28-16.44,36.72-36.72,36.72h-97.91ZM452.09,364.88c-5.03,3.05-10.07,6.12-15.11,9.18-34.88,21.21-69.74,42.44-104.62,63.64-7.76,4.72-15-7.34-7.27-12.04,5.03-3.07,10.08-6.13,15.12-9.19,34.87-21.21,69.74-42.42,104.61-63.65,7.76-4.71,15,7.36,7.27,12.06Z"
    ></path>
    <rect
      x="236.58"
      y="167.18"
      width="120"
      height="160.94"
      rx="5"
      ry="5"
    ></rect>
  </svg>
</template>
<script setup></script>
