<template>
  <div class="intro-container">
    <div class="part">
      <h1 class="header">اِکس پلیمر</h1>
      <div class="text">توانایی و نوآوری در دنیای پلیمر</div>
    </div>
    <div class="part"><img src="/img/introPic.webp" alt="polymer" /></div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.intro-container {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 280px;
  width: 1240px;
  .part {
    &:first-child {
      display: flex;
      flex-direction: column;
    }
    &:last-child {
      margin-top: 1.6rem;
    }
    img {
      height: 440px;
    }
    .header {
      font-size: 70px;
      font-weight: bold;
      color: #1c66e1;
    }
    .text {
      font-size: 35px;
    }
  }
}
@media only screen and (max-width: 1050px) {
  .intro-container {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    height: 260px;
    width: 90%;
    margin: 0 5%;
    .part {
      img {
        height: 350px;
      }
      .header {
        font-size: 60px;
      }
      .text {
        font-size: 30px;
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .intro-container {
    height: 230px;

    .part {
      img {
        height: 300px;
      }
      .header {
        font-size: 50px;
      }
      .text {
        font-size: 20px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .intro-container {
    height: 320px;
    flex-direction: column;
    text-align: center;
    .part {
      img {
        height: 250px;
      }
      .header {
        font-size: 45px;
        padding-bottom: 15px;
      }
      .text {
        font-size: 25px;
      }
    }
  }
}
</style>
