<template>
  <div id="services">
    <div class="category-container mobile">
      <div class="header">
        <h2>
          <span class="bold py-1">خدمات </span>
          <span> اِکس پلیمر </span>
        </h2>
        <div class="text">
          برای مشاهده محتویات هر یک از خدمات زیر، آن را انتخاب کنید.
        </div>
      </div>
      <div class="pictures">
        <div class="arrow-right" @click="prevPicture"><ArrowRightSvg /></div>
        <div class="picture">
          <div class="img-container">
            <img
              :src="pictures[currentIndex].imgSrc"
              :alt="pictures[currentIndex].alt"
            />
          </div>
          <div class="text" @click="goLocation(pictures[currentIndex].url)">
            <span class="pe-2" v-if="currentIndex === 0"><KeepingSvg /></span>
            <span class="pe-2" v-if="currentIndex === 1"><CleanSvg /></span>
            <span class="pe-2" v-if="currentIndex === 2"><ParakSvg /></span>
            <span>{{ pictures[currentIndex].name }}</span>
          </div>
        </div>
        <div class="arrow-left" @click="nextPicture"><ArrowRightSvg /></div>
      </div>
    </div>
    <div class="category-container">
      <div class="logo"><ServicesSvg /></div>
      <div class="header">
        <h2>
          <span class="bold py-1">خدمات </span>
          <span> اِکس پلیمر </span>
        </h2>
        <div class="text">
          برای مشاهده محتویات هر یک از خدمات زیر، آن را انتخاب کنید.
        </div>
      </div>
      <div class="pictures">
        <a
          class="picture"
          target="_blank"
          href="https://expolymer.ir/blog/warehousing/"
        >
          <div class="img-container">
            <img src="/img/keeping.webp" alt="polymer warehouse" />
          </div>
          <div
            class="text"
            @click="goLocation('https://expolymer.ir/blog/warehousing/')"
          >
            <span class="pe-2"><KeepingSvg /></span>
            <span>نگهداری</span>
          </div>
        </a>
        <a
          class="picture"
          target="_blank"
          href="https://expolymer.ir/blog/washing-materials/"
        >
          <div class="img-container">
            <img src="/img/clean.webp" alt="cleaning polymer" />
          </div>
          <div
            class="text"
            @click="goLocation('https://expolymer.ir/blog/washing-materials/')"
          >
            <span class="pe-2"><CleanSvg /></span>
            <span>شست و شوی مواد</span>
          </div>
        </a>
        <a
          class="picture"
          target="_blank"
          href="https://expolymer.ir/blog/feather/"
        >
          <div class="img-container">
            <img src="/img/parak.webp" alt="cutting polymer" />
          </div>
          <div
            class="text"
            @click="goLocation('https://expolymer.ir/blog/feather/')"
          >
            <span class="pe-2"><ParakSvg /></span>
            <span>پرک کردن</span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue";
import ServicesSvg from "@/components/svgs/ServicesSvg.vue";
import ParakSvg from "@/components/svgs/ParakSvg.vue";
import CleanSvg from "@/components/svgs/CleanSvg.vue";
import KeepingSvg from "@/components/svgs/KeepingSvg.vue";
import ArrowRightSvg from "./svgs/ArrowRightSvg.vue";
const goLocation = (e) => {
  // window.location = e;
  window.open(e, "_blank");
};
const pictures = [
  {
    imgSrc: "/img/keeping.webp",
    alt: "polymer warehouse",
    url: "https://expolymer.ir/blog/warehousing/",
    iconSvg: KeepingSvg,
    name: "نگهداری",
  },
  {
    imgSrc: "/img/clean.webp",
    alt: "cleaning polymer",
    url: "https://expolymer.ir/blog/washing-materials/",
    iconSvg: CleanSvg,
    name: "شست و شوی مواد",
  },
  {
    imgSrc: "/img/parak.webp",
    alt: "cutting polymer",
    url: "https://expolymer.ir/blog/feather/",
    iconSvg: ParakSvg,
    name: "پرک کردن",
  },
];

const currentIndex = ref(0);

const nextPicture = () => {
  currentIndex.value = (currentIndex.value + 1) % pictures.length;
};

const prevPicture = () => {
  currentIndex.value =
    (currentIndex.value - 1 + pictures.length) % pictures.length;
};
</script>
<style lang="scss" scoped>
.category-container {
  padding-top: 4rem;
  z-index: 10;
  padding-bottom: 5rem;
  display: flex;
  width: 1240px;
  flex-direction: column;
  background-color: white;
  &.mobile {
    display: none;
  }
  .logo {
    animation: 2s infinite alternate ease-in-out upAndDown;
    svg {
      width: 65px;
      fill: #1c66e1;
    }
  }
  .header {
    text-align: right;
    font-size: 28px;
    font-weight: bold;
    .bold {
      color: #1c66e1;
      padding-left: 5px;
    }
    .text {
      font-size: 14px;
      font-weight: normal;
      color: #b4b4b4;
      margin-top: 10px;
    }
  }
  .pictures {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    .picture {
      position: relative;
      width: 350px;
      .img-container {
        border-radius: 18px;
        border: #1c66e1 2px solid;
        padding: 3px;
        img {
          border-radius: 18px;
          width: 100%;
        }
      }
      .text {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1c66e1;
        position: absolute;
        bottom: -10px;
        right: -10px;
        width: 158px;
        height: 52px;
        border-radius: 20px 0px 20px 0px;
        z-index: 2;
        &:hover {
          cursor: pointer;
          bottom: 0px;
          right: 0px;
          transition: 0.3s;
        }
        span {
          color: white;
          svg {
            fill: white;
            width: 18px !important;
            height: 18px !important;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .category-container {
    width: 90%;
    margin: 0 5%;
    margin-top: 4rem;
    margin-bottom: 5rem;
    .logo {
      svg {
        width: 60px;
      }
    }
    .header {
      font-size: 26px;
      .text {
        font-size: 13px;
      }
    }
    .pictures {
      margin-top: 2rem;
      .picture {
        width: 250px;

        .text {
          bottom: -10px;
          right: -10px;
          width: 140px;
          height: 45px;
          font-size: 14px;

          span {
            svg {
              width: 15px !important;
              height: 15px !important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .category-container {
    width: 95%;
    margin: 0 2.5%;
    margin-top: 4rem;
    margin-bottom: 5rem;
    .logo {
      svg {
        width: 50px;
      }
    }
    .header {
      font-size: 24px;
      .text {
        font-size: 11px;
      }
    }
    .pictures {
      margin-top: 2rem;
      .picture {
        width: 210px;

        .text {
          bottom: -7px;
          right: -7px;
          width: 120px;
          height: 35px;
          font-size: 11px;
          span {
            svg {
              width: 13px !important;
              height: 13px !important;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .category-container {
    display: none;
    width: 95%;
    margin: 0 2.5%;
    margin-top: 1rem;
    margin-bottom: 0;
    &.mobile {
      display: flex;
      .arrow-right {
        position: absolute;
        top: 50%;
        /* transform: translateY(50%); */
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        z-index: 10;
        border: 1px solid #1c66e1;
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .arrow-left {
        position: absolute;
        top: 50%;
        left: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: 1px solid #1c66e1;
        svg {
          transform: rotate(180deg);
          width: 12px;
          height: 12px;
        }
      }
    }
    .logo {
      svg {
        width: 50px;
      }
    }
    .header {
      text-align: center;
      font-size: 24px;
      .text {
        font-size: 11px;
      }
    }
    .pictures {
      margin-top: 2rem;
      position: relative;
      display: flex;
      justify-content: center;
      .picture {
        width: 90%;

        .text {
          bottom: 0px;
          right: 0px;
          border-radius: 0 0 12px 12px;
          height: 35px;
          font-size: 14px;
          width: 100%;
          span {
            svg {
              width: 17px !important;
              height: 17px !important;
            }
          }
        }
      }
    }
  }
}
</style>
